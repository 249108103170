
import Page from '../Page.vue';
import { PageState } from '@/models/PageState';
import { notificationStore } from '@/store/index';
import { Component, Mixins } from 'vue-property-decorator';
import { VuetifyMixin, StringsMixin, PaginatedTableMixin, AthleteApiMixin, CoachRoutingMixin, BAIconsMixin } from '@/mixins';
import { DataTableHeader } from 'vuetify';
import { adminStore } from '@/store/index';
import { AthleteProfileModel } from '@/models';
import { TeamAthleteProgress, AthleteProgressAbbreviations, AthleteProgressFields } from '@/models/team'
import RecruitingProfileInfo from '@/components/profile/recruiting/RecruitingProfileInfo.vue';
import { athleteApi } from '@/api/AthleteApi';

@Component({
	components: {
		Page,
		RecruitingProfileInfo,
	}
})
export default class AthleteAdminDashboard extends Mixins(VuetifyMixin, StringsMixin, PaginatedTableMixin, AthleteApiMixin, CoachRoutingMixin, BAIconsMixin){
	pageState: PageState = new PageState('Initial');
	search: string = '';

	mounted() {
		this.tableOptions.sortBy = ['lastName'];
	}

	async loadTable(): Promise<void>{
		this.pageState = new PageState('Loading');
		try {
			const query = this.TableQuery<AthleteProfileModel>(this.search, ['lastName', 'firstName', 'email', 'id', 'shortId']);
			await adminStore.loadAthletes({ query, options: this.TableQueryOptions });
		} catch(e) {
			notificationStore.pushSnackbarError({message: `Error loading athletes: ${e}`});
		} finally {
			this.pageState = new PageState('Ready');
		}
	}

	get PageLoading(): boolean {
		return adminStore.IsAthletesLoading || !this.IsAthleteApiReady;
	}
	get TableLoading(): boolean {
		return adminStore.IsAthletesLoading;
	}
	get TotalItems(): number {
		return this.Athletes.length;
	}
	get Athletes(): AthleteProfileModel[] {
		if( this.showDeleted ) return adminStore.DeletedAthletes;
		return adminStore.ActiveAthletes;
	}

	get TableHeaders(): Array<DataTableHeader<any>> {
		return [
			{ text: 'Name', value: 'lastName', sortable: true, },
			{ text: 'gen', value: 'gender', sortable: false, },
			{ text: 'DOB', value: 'birthDate', sortable: false, },
			{ text: 'Weight', value: 'weight', sortable: false, },
			{ text: 'Height', value: 'height', sortable: false, },
			{ text: 'pos', value: 'position', sortable: false, },
			{ text: 'foot', value: 'dominantSide', sortable: false, },
			{ text: 'athleteId', value: 'id', sortable: false, },
			{ text: 'shortId', value: 'shortId', sortable: false, },
			{ text: 'share', value: 'sharingUrlId', sortable: false},
			{ text: 'email', value: 'email', sortable: false, },
			{ text: '', value: 'data-table-expand' }
		]
	}

	duplicateLoading: boolean = false;
	duplicates: Array<AthleteProfileModel> = [];
	noDuplicatesFound: boolean = false;
	primaryLoaded: boolean = false;
	primaryAccount: AthleteProfileModel = undefined;
	mergedDuplicates: AthleteProfileModel = undefined;
	readyToMerge: boolean = false;
	dateKeys: Array<string> = ['birthDate','deleted_at','last_login'];
	mergeData: Array<Record<string,any>> = [];
	showMergeDuplicates: boolean = false;
	mergeSkipLoading: boolean = false;
	detailsLoading: boolean = false;
	showAthleteDetail: boolean = false;
	showDeleted: boolean = false;

	async onFindDuplicates(authId: string = undefined) {
		this.duplicateLoading = true;
		this.findDuplicates(authId);
	}
	async findDuplicates(authId: string = undefined) {
		if( this.IsEmpty(authId) ) this.duplicates = await athleteApi.findDuplicates(this.IsNotEmpty(this.search)? this.search : '');
		else this.duplicates = await athleteApi.findUserDuplicates(authId);
		
		this.findDuplicatesComplete();
	}
	async findDuplicatesComplete() {
		if( this.IsEmpty(this.duplicates) ) {
			this.noDuplicatesFound = true;
		} else {
			this.duplicates.forEach(d => {
				d.useInMerge = true;
			});
			let primary: AthleteProfileModel = undefined;
			for( const d of this.duplicates ) {
				if( this.IsEmpty(primary) || (d.ProgressValue > primary.ProgressValue) ) {
					primary = d;
				}
			}
			this.onSelectPrimary(primary.id);
		}
		this.showAthleteDetail = true;
		this.duplicateLoading = false;
	}
	get HasDuplicates(): boolean {
		if( this.duplicateLoading ) return false;
		return this.IsNotEmptyArray(this.duplicates);
	}
	get DuplicatesName(): string {
		if( !this.HasDuplicates ) return 'unknown';
		
		if( this.IsNotEmpty(this.primaryAccount) ) {
			if( this.IsNotEmpty(this.primaryAccount.firstName) || this.IsNotEmpty(this.primaryAccount.lastName) ) {
				return this.FullName(this.primaryAccount.firstName, this.primaryAccount.lastName);
			}
		}
		for( const dup of this.duplicates ) {
			if( this.IsNotEmpty(dup.firstName) || this.IsNotEmpty(dup.lastName) ) {
				return this.FullName(dup.firstName, dup.lastName);
			}
		}
		return 'unknown'
	}
	mergedProgress: TeamAthleteProgress;
	private async PrepareMerge() {		
		this.readyToMerge = false;
		if( this.IsEmpty(this.primaryAccount) ) return;

		this.mergedDuplicates = new AthleteProfileModel(this.primaryAccount);
		if( this.IsEmpty(this.mergedDuplicates.altEmail)) this.mergedDuplicates.altEmail = [];
		this.duplicates.forEach( d => {
			if( this.IsDupPrimary(d.id) ) return;
			if( !d.useInMerge ) return;

			for( const key of Object.keys(d) ) {
				if( this.IsNotEmpty(d[key]) ) {
					if( this.IsEmpty(this.mergedDuplicates[key]) ) {
						this.mergedDuplicates[key] = d[key];
					}
					if( this.dateKeys.includes(key)) {
						if( d[key] > this.mergedDuplicates[key] ) this.mergedDuplicates[key] = d[key];
					}
				}
			}

			if( this.mergedDuplicates.email != d.email && !this.mergedDuplicates.altEmail.includes(d.email) ) this.mergedDuplicates.altEmail.push(d.email);
		})
		await athleteApi.UpdateSports(this.mergedDuplicates, false);

		// progress
		this.mergedProgress = this.mergedDuplicates.InitProgress();
		this.duplicates.forEach( d => {
			if( !this.mergedProgress.hasVerifiedAssessment ) this.mergedProgress.hasVerifiedAssessment = d.progress?.hasVerifiedAssessment;
			if( !this.mergedProgress.mindsetArchetype ) this.mergedProgress.mindsetArchetype = d.progress?.mindsetArchetype;
			if( !this.mergedProgress.hasScoutingReport ) this.mergedProgress.hasScoutingReport = d.progress?.hasScoutingReport;
		})

		// merge data to display
		const keysToShow: Array<{key: string, label: string}> = [
			{ key:'firstName', label:'First Name' },
			{ key:'lastName', label:'Last Name' },
			{ key:'email', label:'Email' },
			{ key:'altEmail', label:'Alt Email' },
			{ key:'id', label:'id' },
			{ key:'birthDate', label:'Date of Birth' },
			{ key:'gender', label:'Gender' },
			{ key:'height', label:'Height' },
			{ key:'weight', label:'Weight' },
			{ key:'primaryPosition', label:'Position' },
			{ key:'gradYear', label:'Grad Year' },
			{ key:'gpa', label:'GPA' },
		];

		this.mergeData = keysToShow.map(show => {
			let value = this.mergedDuplicates[show.key];
			
			if( show.key === "id" ) value = this.SnipAthleteId(this.mergedDuplicates.id);
			else if( show.key === "email" ) value = this.SnipEmail(this.mergedDuplicates.Email);
			else if( show.key === "height" ) value = this.HeightInFt(this.mergedDuplicates.height);
			else if( show.key === "weight" ) value = this.WeightInLbs(this.mergedDuplicates.weight);
			else if( show.key === "birthDate" ) value = this.mergedDuplicates.BirthDate;
			else if( show.key === "gradYear" ) value = this.mergedDuplicates.GradYear?.toFixed(0);
			else if( show.key === "gpa" ) value = this.mergedDuplicates.GPA;

			return { key: show.label, value };
		});

		this.readyToMerge = this.IsNotEmpty(this.primaryAccount) && (this.MergeCount > 1);
	}
	onClearDuplicates() {
		this.cleanupMerge();
	}
	cleanupMerge() {
		this.duplicates = [];
		this.primaryAccount = undefined;
		this.mergedDuplicates = undefined;
		this.noDuplicatesFound = false;
		this.readyToMerge = false;
		this.primaryLoaded = false;
	}
	async onSkipMergeDuplicates() {
		this.mergeSkipLoading = true;
		await athleteApi.mergeSkipAccounts(this.duplicates.map(d => d.id));
		this.mergeSkipLoading = false;
		this.cleanupMerge();
	}
	async onMergeDuplicates() {
		if( !this.readyToMerge ) {
			notificationStore.pushSnackbarError({message: 'Please select the primary account'}) 
			return;
		}
		this.PrepareMerge();
		this.showMergeDuplicates = true;
	}
	IsDupPrimary( athleteId: string ): boolean {
		if( this.IsEmpty(this.primaryAccount) ) return false;
		return athleteId === this.primaryAccount.id;
	}
	get DuplicatesCount(): number {
		return this.duplicates.length;
	}
	get DuplicatesToMerge(): Array<AthleteProfileModel> {
		return this.duplicates.filter(d => d.useInMerge);
	}
	get MergeCount(): number {
		return this.DuplicatesToMerge.length;
	}
	async MergeDuplicatesConfirm() {
		this.showMergeDuplicates = false;
		notificationStore.pushSnackbarWarning({message:`Merging User Accounts...`});

		// the first id must be that of the primary account
		const mergeIds = [this.primaryAccount.id];
		// add the selected duplicates
		mergeIds.push(...this.DuplicatesToMerge.map(d => d.id));
		athleteApi.mergeAccounts(mergeIds);
		this.cleanupMerge();
	}
	async onSelectPrimary(athleteId: string) {
		this.primaryLoaded = false;
		this.primaryAccount = undefined;

		this.duplicates.forEach(d => { d.primary = ( d.id === athleteId ) });
		const primary = this.duplicates.find(d => d.primary === true);
		if( this.IsNotEmpty(primary) ) primary.useInMerge = true;
		this.primaryAccount = new AthleteProfileModel(primary);
		await this.PrepareMerge();
		this.primaryLoaded = true;
	}
	async onUseInMerge( athleteId: string ) {
		this.primaryLoaded = false;
		await this.PrepareMerge();
		this.primaryLoaded = true;
	}
	async onToggleAthleteDetail() {
		this.detailsLoading = true;
		this.showAthleteDetail = !this.showAthleteDetail;
		this.detailsLoading = false;
	}
	AthleteProgressData(progress: TeamAthleteProgress, abbreviatedLabels: boolean = true): Array<Record<string,any>> {
		return this.KeysToShowAthleteProgress.map(k => {
			const key = abbreviatedLabels? AthleteProgressAbbreviations[k] : AthleteProgressFields[k];
			const value: any = this.IsNotEmpty(progress)? progress[k] : undefined;
			return {key, value};
		});
	}

	get MergeHeaders(): Array<DataTableHeader<any>> {
		let headers: Array<DataTableHeader<any>> = [
			{ text: 'Key', value: 'key', sortable: false },
			{ text: 'Value', value: 'value', sortable: false },
		];
		return headers;
	}
	get KeysToShowAthleteProgress(): Array<string> {
		return [
			'hasPhoto',
			'hasEmail',
			'hasPhone',
			'hasInsta',
			'hasTwitter',
			'hasBio',
			'hasDOB',
			'hasHeight',
			'hasWeight',
			'hasDominant',
			'hasLocation',
			'hasHighlights',
			'hasGradYear',
			'hasGPA',
			'hasSAT',
			'hasVerifiedAssessment',
			'mindsetArchetype',
			'hasScoutingReport',
			'commitment',
		]
	}
}

